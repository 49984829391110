import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/apiService';
import './LoginPage.css';
import CryptoJS from 'crypto-js';

// Função de validação de CPF
const isValidCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não é dígito
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;

    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i-1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i-1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
    return true;
};

const LoginPage = () => {
    const [loginInput, setLoginInput] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        setError(''); // Reset error message

        if (!isValidCPF(loginInput)) {
            setError('CPF inválido!');
            return;
        }

        try {
            const data = await login(loginInput, password);
            if (data.token) {
                        // Criptografar o CPF antes de armazenar
        const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const encryptedCPF = CryptoJS.AES.encrypt(loginInput, secretKey).toString();
  
                localStorage.setItem('cod2', data.token);
                localStorage.setItem('cod', encryptedCPF); // Armazena o CPF (ou login) no localStorage
                navigate('/aulas', { replace: true });
            } else {
                setError('Login falhou!');
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            setError('Erro ao fazer login. Verifique suas credenciais e tente novamente.');
        }
    };

    const handleLoginChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
        setLoginInput(value);
    };

    return (
        <div className="login-container">
            <div className="login-left">
                <h1>2T Teórico Web</h1>
                <p>Sua plataforma de captura biométrica.</p>
            </div>
            <div className="login-right">
                <h2>Fazer login Aluno</h2>
                <input
                    type="text"
                    value={loginInput}
                    onChange={handleLoginChange}
                    placeholder="CPF somente números"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha (Provisoriamente o CPF)"
                />
                <button onClick={handleLogin}>Entrar</button>
                {error && <p className="error-message">{error}</p>}
                <p>Em caso de dúvidas de como acessar, entre em contato com a sua Auto Escola!</p>
            </div>
        </div>
    );
};

export default LoginPage;

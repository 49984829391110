import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { envioBiometria, getRetornoAula } from "../services/apiService"; // Certifique-se de importar getRetornoAula
import { Button, makeStyles, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, RadioGroup, Radio, Grid, FormControlLabel, FormControl, FormLabel, CircularProgress } from '@material-ui/core';
import { AppContext } from "../context/AppContext";
import "./EnvioPage.css";
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "4px",
  },
}));

const EnvioPage = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Iniciar com true para mostrar o carregamento
  const { capturedImage, codAulaTeorica, codAluno } = useContext(AppContext);
  const [etapa, setEtapa] = useState(1); // Estado para a etapa (1 para entrada, 2 para saída)
  const [entradaRealizada, setEntradaRealizada] = useState(false);
  const [description, setDescription] = useState({
    titulo: "Aviso",
    mensagem: "Mensagem",
  });
  // Função para descriptografar o CPF
  const decryptData = (ciphertext, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // Recupera e descriptografa o CPF do localStorage
  const getDecryptedCPF = () => {
    const encryptedCPF = localStorage.getItem('cod');
    if (encryptedCPF) {
      const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
      const decryptedCPF = decryptData(encryptedCPF, secretKey);
      return decryptedCPF;
    }
    return null;
  };
  useEffect(() => {
    const fetchBiometriaStatus = async () => {
      try {
        const cpf = getDecryptedCPF();
        const status = await getRetornoAula(codAulaTeorica, cpf);
        const entrada = status.find((s) => s.etapa === 1);
        //console.log(status);

        if (entrada && entrada.dataHoraColeta) {
          setEntradaRealizada(true);
          setEtapa(2); // Seleciona automaticamente "Saída" se "Entrada" já foi realizada
        }
      } catch (error) {
        console.error("Erro ao buscar status da biometria:", error);
      } finally {
        setLoading(false); // Desativa o carregamento após buscar os dados
      }
    };

    fetchBiometriaStatus();
  }, [codAulaTeorica, codAluno]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const cleanedImage = capturedImage.replace("data:image/jpeg;base64,", "");
      const response = await envioBiometria(
        codAulaTeorica,
        codAluno,
        etapa,
        1,
        2,
        0,
        cleanedImage
      );
      const { codigoRetornoBiometria, mensagemBiometria } = response;
      if (codigoRetornoBiometria === 1) {
          navigate('/sucesso', { state: { message: "Imagem realizada com sucesso!" }, replace: true });
      } else {
          const errorMessage = mensagemBiometria || "Erro desconhecido na biometria.";
          navigate('/falha', { state: { message: errorMessage }, replace: true });
      }
    } catch (error) {
      console.error("Erro no envio", error);
      navigate('/falha', { state: { message: error.response?.data?.message || error.message }, replace: true });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="envio-page">
      <Typography
        variant="h3"
        style={{ textAlign: "center", fontSize: "3rem" }}
        component="h1"
      >
        Enviar Biometria
      </Typography>
      {capturedImage && (
        <img src={capturedImage} alt="Foto Capturada" className="photo" />
      )}
      <div className="etapa-selection">
        <FormControl component="fieldset">
          <FormLabel component="legend">Você está fazendo?</FormLabel>
          <RadioGroup
            aria-label="etapa"
            name="etapa"
            value={etapa}
            onChange={(ev) => setEtapa(Number(ev.target.value))}
          >
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Radio value={1} color="primary" disabled={entradaRealizada} />
                  }
                  label="Entrada"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Radio value={2} color="primary" />
                  }
                  label="Saída"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </div>
      <Grid
        container
        justifyContent="center"
        style={{ gap: "1rem", maxWidth: "600px", marginInline: "auto" }}
      >
        <Grid item xs={5} sm={5}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSend}
          >
            Enviar
          </Button>
        </Grid>
        <Grid item xs={5} sm={5}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => navigate("/capture")}
          >
            Refazer
          </Button>
        </Grid>
      </Grid>
      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{description.titulo}</DialogTitle>
          <DialogContent>
            <Typography>{description.mensagem}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default EnvioPage;

// import React, { useEffect, useState, useContext } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import "./AulasPage.css";
// import { formatISO } from "date-fns";
// import { AppContext } from "../context/AppContext";
// import { getRetornoAula } from "../services/apiService";
// import {
//   Box,
//   Button,
//   Card,
//   CardContent,
//   makeStyles,
//   Container,
//   Grid,
//   Typography,
//   Divider,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@material-ui/core";
// import CryptoJS from 'crypto-js';

// const useStyles = makeStyles((theme) => ({
//   nomeAluno: {
//     fontSize: "1.5rem", // Tamanho de fonte padrão
//     fontWeight: "bolder",
//     textAlign: "left",
//     flexGrow: 1,
//     [theme.breakpoints.down("xs")]: {
//       fontSize: "1.2rem", // Ajuste para telas menores (menos que 600px de largura)
//     },
//   },
//   captureButton: {
//     margin: "1.5rem auto 0",
//     display: "block",
//     backgroundColor: "blue",
//     color: "white",
//     "&:hover": {
//       backgroundColor: "green",
//     },
//   },
//   captureButtonDisabled: {
//     backgroundColor: "gray",
//     color: "white",
//     "&:hover": {
//       backgroundColor: "black",
//     },
//   },
//   entrarButton: {
//     maxWidth: "170px",
//     marginLeft: theme.spacing(2),
//     backgroundColor: "lightgray",
//     color: "darkgray",
//     "&:hover": {
//       backgroundColor: "gray",
//     },
//     "&.enabled": {
//       backgroundColor: "purple",
//       color: "white",
//       "&:hover": {
//         backgroundColor: "darkorchid",
//       },
//     },
//   },
//   cardContainer: {
//     position: "relative",
//   },
//   headerContainer: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//   },
// }));

// const AulasPage = () => {
//   const classes = useStyles();
//   const [aulas, setAulas] = useState([]);
//   const [statusEntrada, setStatusEntrada] = useState({});
//   const [statusSaida, setStatusSaida] = useState({});
//   const [modalOpen, setModalOpen] = useState(false);
//   const [modalMessage, setModalMessage] = useState("");
//   const navigate = useNavigate();
//   const { setCodAulaTeorica, setCodAluno } = useContext(AppContext);


//       // Função para descriptografar o CPF
// const decryptData = (ciphertext, secretKey) => {
//   const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
//   return bytes.toString(CryptoJS.enc.Utf8);
// };

// // Recupera e descriptografa o CPF do localStorage
// const getDecryptedCPF = () => {
//   const encryptedCPF = localStorage.getItem('cod');
//   if (encryptedCPF) {
//     const secretKey = process.env.REACT_APP_ENCRYPTION_KEY; // Acessa a chave do .env
//     const decryptedCPF = decryptData(encryptedCPF, secretKey);
//     return decryptedCPF;
//   }
//   return null; // Caso não haja CPF armazenado
// };

//   useEffect(() => {
//     const fetchAulas = async () => {
//       const token = localStorage.getItem('cod2');
//       const cpf = getDecryptedCPF();
//       if (!token || !cpf) {
//         alert("Usuário não autenticado!");
//         navigate("/");
//         return;
//       }

//       const now = new Date();
//       const dataInicio = formatISO(
//         new Date(now.getFullYear(), now.getMonth(), now.getDate(), 4, 0, 0),
//         { representation: "complete" }
//       );
//       const dataFim = formatISO(
//         new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
//         { representation: "complete" }
//       );

//       try {
//         const response = await axios.get("/2TSistemas/AulaTeorica/Aluno", {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//           params: {
//             cpf: cpf,
//             dataInicio: dataInicio.slice(0, 19),
//             dataFim: dataFim.slice(0, 19),
//           },
//         });
//         // //console.log(response.data);
//         setAulas(response.data);

//         // Fetch status for each aula
//         response.data.forEach(async (aula) => {
//           const status = await getRetornoAula(aula.aulaCodigo, cpf);
//           const entrada = status.find((s) => s.etapa === 1);
//           const saida = status.find((s) => s.etapa === 2);
//           setStatusEntrada((prevStatus) => ({
//             ...prevStatus,
//             [aula.aulaCodigo]: entrada
//               ? {
//                 text: "Realizada às " + formatDate(entrada.dataHoraColeta),
//                 color: "green",
//               }
//               : { text: "Entrada não realizada", color: "red" },
//           }));
//           setStatusSaida((prevStatus) => ({
//             ...prevStatus,
//             [aula.aulaCodigo]: saida
//               ? {
//                 text: "Realizada às " + formatDate(saida.dataHoraColeta),
//                 color: "green",
//               }
//               : { text: "Saída não realizada", color: "red" },
//           }));
//         });
//       } catch (error) {
//         console.error("Erro ao buscar aulas:", error);
//         alert("Erro ao buscar aulas");
//       }
//     };

//     fetchAulas();
//   }, [navigate]);

//   const formatDate = (dateString) => {
//     const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
//     return new Date(dateString).toLocaleTimeString("pt-BR", options);
//   };

//   const formatDateTime = (dateString) => {
//     const options = {
//       year: "2-digit",
//       month: "2-digit",
//       day: "2-digit",
//       hour: "2-digit",
//       minute: "2-digit",
//       second: "2-digit",
//     };
//     return new Date(dateString).toLocaleString("pt-BR", options);
//   };

//   const isCaptureAllowed = (aula) => {
//     const now = new Date();
//     const aulaInicio = new Date(aula.aulaDataHora);
//     const aulaFim = new Date(
//       aulaInicio.getTime() + aula.aulaQuantidade * 60 * 50 * 1000
//     ); // Aula dura 50 minutos cada

//     const startCapture = new Date(aulaInicio.getTime() - 27 * 60 * 1000); // 27 minutos antes da aula
//     const endCapture = new Date(aulaFim.getTime() - 30 * 1000); // 30 segundos antes do término
//     const startPostAulaCapture = aulaFim; // Início logo após a aula
//     const endPostAulaCapture = new Date(
//       aulaFim.getTime() + 39 * 60 * 1000 + 59 * 1000
//     ); // 39 minutos e 59 segundos após o término

//     return (
//       (now >= startCapture && now <= endCapture) ||
//       (now >= startPostAulaCapture && now <= endPostAulaCapture)
//     );
//   };

//   const handleCapture = (aula) => {
//     if (isCaptureAllowed(aula)) {
//       setCodAulaTeorica(aula.aulaCodigo);
//       setCodAluno(aula.alunoCodigo);
//       navigate("/capture");
//     } else {
//       setModalMessage(
//         "A Captura de entrada é permitida apenas a partir de 30 minutos antes do início da aula e até 15 minutos após o início da aula. A captura de saída é permitida apenas após o término da aula e até 40 minutos depois."
//       );
//       setModalOpen(true);
//     }
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//   };

//   const handleEnterClass = (aula) => {
//     // Logic to handle entering the class
//     // //console.log(`Entering class: ${aula.linkAula}`);
//     // Here you can add the navigation or any action needed to enter the class
//     // Verifica se o link da aula está disponível
//     if (aula && aula.linkAula) {
//       // Abre o link da aula em uma nova aba do navegador
//       window.open(aula.linkAula, "_blank");
//       // //console.log(`Entering class: ${aula.linkAula}`);
//     } else {
//       console.error("Link da aula não disponível");
//     }
//   };

//   return (
//     <Container style={{ paddingTop: "2rem" }}>
//       <Typography variant="h3" style={{ textAlign: "center" }} component="h1">
//         Lista de Aulas
//       </Typography>
//       <div>
//         {aulas.length > 0 ? (
//           aulas.map((aula, index) => (
//             <Box m={4} key={index} fontSize="1rem">
//               <Card
//                 className={classes.cardContainer}
//                 style={{ maxWidth: "550px", margin: "1rem auto" }}
//               >
//                 <CardContent>
//                   <div className={classes.headerContainer}>
//                     <Typography className={classes.nomeAluno} component="h2">
//                       {aula.alunoNome.trim()}
//                     </Typography>
//                     <Button
//                       variant="contained"
//                       size="small"
//                       className={`${classes.entrarButton} ${statusEntrada[aula.aulaCodigo]?.text.includes(
//                         "Realizada"
//                       )
//                         ? "enabled"
//                         : ""
//                         }`}
//                       onClick={() => handleEnterClass(aula)}
//                       disabled={
//                         !statusEntrada[aula.aulaCodigo]?.text.includes(
//                           "Realizada"
//                         )
//                       }
//                     >
//                       Entrar na Aula
//                     </Button>
//                   </div>
//                   <Typography
//                     style={{ textAlign: "center", marginBottom: "0.8rem" }}
//                     variant="body1"
//                     component="h3"
//                   >
//                     Instrutor: {aula.instrutorNome}
//                   </Typography>
//                   <Grid container spacing={1} style={{ margin: "0.3rem 0" }}>
//                     <Grid item xs={12} sm={6} style={{ padding: "0" }}>
//                       <Typography component="p">
//                         Início: {formatDateTime(aula.aulaDataHora)}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={6} style={{ padding: "0" }}>
//                       <Typography component="p">
//                         Término:{" "}
//                         {formatDateTime(
//                           new Date(
//                             new Date(aula.aulaDataHora).getTime() +
//                             aula.aulaQuantidade * 60 * 50 * 1000
//                           ).toISOString()
//                         )}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                       <Divider
//                         style={{ alignItems: "center", margin: "auto" }}
//                       />
//                     </Grid>
//                     <Grid item xs={12} sm={6} style={{ padding: "0" }}>
//                       <Typography component="p">
//                         Quantidade de Aulas: {aula.aulaQuantidade}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={6} style={{ padding: "0" }}>
//                       <Typography component="p">
//                         Código da Aula: {aula.aulaCodigo}
//                       </Typography>
//                     </Grid>
//                     <Grid item xs={12}>
//                       <Divider
//                         style={{ alignItems: "center", margin: "auto" }}
//                       />
//                     </Grid>
//                     <Grid
//                       item
//                       xs={12}
//                       sm={6}
//                       style={{
//                         padding: "0",
//                         color: statusEntrada[aula.aulaCodigo]?.color,
//                       }}
//                     >
//                       <Typography component="p">
//                         Entrada: {statusEntrada[aula.aulaCodigo]?.text}
//                       </Typography>
//                     </Grid>
//                     <Grid
//                       item
//                       xs={12}
//                       sm={6}
//                       style={{
//                         padding: "0",
//                         color: statusSaida[aula.aulaCodigo]?.color,
//                       }}
//                     >
//                       <Typography component="p">
//                         Saída: {statusSaida[aula.aulaCodigo]?.text}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                   <Button
//                     variant="contained"
//                     size="large"
//                     fullWidth
//                     className={`${classes.captureButton} ${!isCaptureAllowed(aula)
//                       ? classes.captureButtonDisabled
//                       : ""
//                       }`}
//                     onClick={() => handleCapture(aula)}
//                   >
//                     Capturar Foto
//                   </Button>
//                 </CardContent>
//               </Card>
//             </Box>
//           ))
//         ) : (
//           <Typography
//             variant="h5"
//             component="p"
//             style={{ textAlign: "center", margin: "2rem auto" }}
//           >
//             Não foram encontradas aulas para o CPF cadastrado!
//           </Typography>
//         )}
//       </div>
//       <Dialog open={modalOpen} onClose={handleCloseModal}>
//         <DialogTitle>Aviso</DialogTitle>
//         <DialogContent>
//           <Typography>{modalMessage}</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseModal} color="primary">
//             Fechar
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default AulasPage;
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatISO } from "date-fns";
import { AppContext } from "../context/AppContext";
import { getRetornoAula } from "../services/apiService";
import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  Container,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({
  nomeAluno: {
    fontSize: "1.5rem",
    fontWeight: "bolder",
    textAlign: "left",
    flexGrow: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  captureButton: {
    margin: "1.5rem auto 0",
    display: "block",
    backgroundColor: "blue",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  captureButtonDisabled: {
    backgroundColor: "gray",
    color: "white",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  entrarButton: {
    maxWidth: "170px",
    marginLeft: theme.spacing(2),
    backgroundColor: "lightgray",
    color: "darkgray",
    "&:hover": {
      backgroundColor: "gray",
    },
    "&.enabled": {
      backgroundColor: "purple",
      color: "white",
      "&:hover": {
        backgroundColor: "darkorchid",
      },
    },
  },
  cardContainer: {
    position: "relative",
    border: "1px solid #ccc",
    borderRadius: "10px",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "600px",
    margin: "1rem auto",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
}));

const AulasPage = () => {
  const classes = useStyles();
  const [aulas, setAulas] = useState([]);
  const [statusEntrada, setStatusEntrada] = useState({});
  const [statusSaida, setStatusSaida] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const navigate = useNavigate();
  const { setCodAulaTeorica, setCodAluno } = useContext(AppContext);

  // Função para descriptografar o CPF
  const decryptData = (ciphertext, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // Recupera e descriptografa o CPF do localStorage
  const getDecryptedCPF = () => {
    const encryptedCPF = localStorage.getItem('cod');
    if (encryptedCPF) {
      const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
      const decryptedCPF = decryptData(encryptedCPF, secretKey);
      return decryptedCPF;
    }
    return null;
  };

  useEffect(() => {
    const fetchAulas = async () => {
      const token = localStorage.getItem('cod2');
      const cpf = getDecryptedCPF();
      if (!token || !cpf) {
        alert("Usuário não autenticado!");
        navigate("/");
        return;
      }

      const now = new Date();
      const dataInicio = formatISO(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 4, 0, 0),
        { representation: "complete" }
      );
      const dataFim = formatISO(
        new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
        { representation: "complete" }
      );

      try {
        const response = await axios.get("/2TSistemas/AulaTeorica/Aluno", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            cpf: cpf,
            dataInicio: dataInicio.slice(0, 19),
            dataFim: dataFim.slice(0, 19),
          },
        });

        setAulas(response.data);

        for (const aula of response.data) {
          const status = await getRetornoAula(aula.aulaCodigo, cpf);
          const entrada = status.find((s) => s.etapa === 1);
          const saida = status.find((s) => s.etapa === 2);
          setStatusEntrada((prevStatus) => ({
            ...prevStatus,
            [aula.aulaCodigo]: entrada
              ? {
                text: "Realizada às " + formatDate(entrada.dataHoraColeta),
                color: "green",
              }
              : { text: "Entrada não realizada", color: "red" },
          }));
          setStatusSaida((prevStatus) => ({
            ...prevStatus,
            [aula.aulaCodigo]: saida
              ? {
                text: "Realizada às " + formatDate(saida.dataHoraColeta),
                color: "green",
              }
              : { text: "Saída não realizada", color: "red" },
          }));
        }

        setLoading(false); // Desativa o carregamento após buscar os dados
      } catch (error) {
        console.error("Erro ao buscar aulas:", error);
        alert("Erro ao buscar aulas");
        setLoading(false); // Desativa o carregamento em caso de erro
      }
    };

    fetchAulas();
  }, [navigate]);

  const formatDate = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
    return new Date(dateString).toLocaleTimeString("pt-BR", options);
  };

  const formatDateTime = (dateString) => {
    const options = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return new Date(dateString).toLocaleString("pt-BR", options);
  };

  const isCaptureAllowed = (aula) => {
    const now = new Date();
    const aulaInicio = new Date(aula.aulaDataHora);
    const aulaFim = new Date(
      aulaInicio.getTime() + aula.aulaQuantidade * 60 * 50 * 1000
    );

    const startCapture = new Date(aulaInicio.getTime() - 27 * 60 * 1000);
    const endCapture = new Date(aulaFim.getTime() - 30 * 1000);
    const startPostAulaCapture = aulaFim;
    const endPostAulaCapture = new Date(
      aulaFim.getTime() + 39 * 60 * 1000 + 59 * 1000
    );

    return (
      (now >= startCapture && now <= endCapture) ||
      (now >= startPostAulaCapture && now <= endPostAulaCapture)
    );
  };

  const handleCapture = (aula) => {
    if (isCaptureAllowed(aula)) {
      setCodAulaTeorica(aula.aulaCodigo);
      setCodAluno(aula.alunoCodigo);
      navigate("/capture");
    } else {
      setModalMessage(
        "A Captura de entrada é permitida apenas a partir de 30 minutos antes do início da aula e até 15 minutos após o início da aula. A captura de saída é permitida apenas após o término da aula e até 40 minutos depois."
      );
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEnterClass = (aula) => {
    if (aula && aula.linkAula) {
      window.open(aula.linkAula, "_blank");
    } else {
      console.error("Link da aula não disponível");
    }
  };

  return (
    <Container style={{ paddingTop: "2rem" }}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Typography variant="h3" style={{ textAlign: "center" }} component="h1">
            Lista de Aulas
          </Typography>
          <div>
            {aulas.length > 0 ? (
              aulas.map((aula, index) => (
                <Box m={4} key={index} fontSize="1rem">
                  <Card className={classes.cardContainer}>
                    <CardContent>
                      <div className={classes.headerContainer}>
                        <Typography className={classes.nomeAluno} component="h2">
                          {aula.alunoNome.trim()}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          className={`${classes.entrarButton} ${statusEntrada[aula.aulaCodigo]?.text.includes(
                            "Realizada"
                          )
                            ? "enabled"
                            : ""
                            }`}
                          onClick={() => handleEnterClass(aula)}
                          disabled={
                            !statusEntrada[aula.aulaCodigo]?.text.includes(
                              "Realizada"
                            )
                          }
                        >
                          Entrar na Aula
                        </Button>
                      </div>
                      <Typography
                        style={{ textAlign: "left", marginBottom: "0.8rem" }}
                        variant="body1"
                        component="h3"
                      >
                        Instrutor: {aula.instrutorNome}
                      </Typography>
                      <Grid container spacing={1} style={{ margin: "0.3rem 0" }}>
                        <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                          <Typography component="p">
                            Início: {formatDateTime(aula.aulaDataHora)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                          <Typography component="p">
                            Término:{" "}
                            {formatDateTime(
                              new Date(
                                new Date(aula.aulaDataHora).getTime() +
                                aula.aulaQuantidade * 60 * 50 * 1000
                              ).toISOString()
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ alignItems: "center", margin: "auto" }} />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                          <Typography component="p">
                            Quantidade de Aulas: {aula.aulaQuantidade}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ padding: "0" }}>
                          <Typography component="p">
                            Código da Aula: {aula.aulaCodigo}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider style={{ alignItems: "center", margin: "auto" }} />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{
                            padding: "0",
                            color: statusEntrada[aula.aulaCodigo]?.color,
                          }}
                        >
                          <Typography component="p">
                            Entrada: {statusEntrada[aula.aulaCodigo]?.text}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{
                            padding: "0",
                            color: statusSaida[aula.aulaCodigo]?.color,
                          }}
                        >
                          <Typography component="p">
                            Saída: {statusSaida[aula.aulaCodigo]?.text}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        className={`${classes.captureButton} ${!isCaptureAllowed(aula)
                          ? classes.captureButtonDisabled
                          : ""
                          }`}
                        onClick={() => handleCapture(aula)}
                      >
                        Capturar Foto
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
              ))
            ) : (
              <Typography
                variant="h5"
                component="p"
                style={{ textAlign: "center", margin: "2rem auto" }}
              >
                Não foram encontradas aulas para o CPF cadastrado!
              </Typography>
            )}
          </div>
          <Dialog open={modalOpen} onClose={handleCloseModal}>
            <DialogTitle>Aviso</DialogTitle>
            <DialogContent>
              <Typography>{modalMessage}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default AulasPage;
